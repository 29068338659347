import React, { useEffect, useMemo, useState, useRef, MutableRefObject } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardLayout from "react-lib/apps/common/CardLayout";
// ui ADM
import CardPreAssessmentUX from "./CardPreAssessmentUX";
import CardPreAssessmentGeneralUX from "./CardPreAssessmentGeneralUX";
import CardPreAssessmentHealthUX from "./CardPreAssessmentHealthUX";
import CardPreAssessmentHeaderUX from "./CardPreAssessmentHeaderUX";
import CardPreAssessmentActionLogUX from "./CardPreAssessmentActionLogUX";
import { Button, Input, Modal, Segment } from "semantic-ui-react";
import { formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import { PreventClickEvent } from "../ORM/CardORRequest";
import SubProvisionDiag from "react-lib/apps/DPI/SubProvisionDiag";
import ModInfoError from "react-lib/apps/common/ModInfo";
import { useIntl } from "react-intl";

const CARD_PRE_ASSESSMENT_ID: string = "CardPreAssessment";

type CardPreAssessmentProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  closeSave?: any;

  // seq
  runSequence: any;
  PreAssessmentSequence?: any;
  preAssessmentActionLog?: any;
  selectedPatient?: any;
  selectedEncounter?: any;
  subICDController?: any;

  // data
  encounterId?: number | null;
  emrId?: number;
  isPHV?: boolean;

  // options
  masterOptions?: any;
  preAssessmentOptions?: any;

  // config
  readOnly?: boolean;
  headerColor?: string;
  closeIcon?: boolean;

  // callback
  onClose?: Function;
};

const CardPreAssessmentInitial: CardPreAssessmentProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // seq
  runSequence: null,
  PreAssessmentSequence: {},

  // options
  masterOptions: {},
  preAssessmentOptions: {},

  // config
  readOnly: false,
  headerColor: "",
};

const presentIntelligence = [
  "recognition_before",
  "memory_and_response_before",
  "hearing_before",
  "seeing_before",
  "touch_before",
  "recognition_present",
  "memory_and_response_present",
  "hearing_present",
  "seeing_present",
  "touch_present",
];

const CardPreAssessment: React.FC<CardPreAssessmentProps> = (props: CardPreAssessmentProps) => {
  const intl = useIntl();
  const subProvisionDiagRef = useRef();
  const [openLog, setOpenLog] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [textReason, setTextReason] = useState("");
  const [openTextAlert, setOpenTextAlert] = useState(false);
  const [beforeDetail, setBeforeDetail] = useState(false);

  const cardRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.runSequence({
      sequence: "PreAssessment",
      restart: true,
      encounterId: props.encounterId,
      emrId: props.emrId,
      selectedPatient: props.selectedPatient,
    });
  }, []);

  useEffect(() => {
    if (props.PreAssessmentSequence?.provisional_diagnosis) {
      subProvisionDiagRef.current.setData(props.PreAssessmentSequence?.provisional_diagnosis);
    }
  }, [props.PreAssessmentSequence?.provisional_diagnosis]);

  useEffect(() => {
    if (cardRef.current) {
      PreventClickEvent(!props.readOnly, cardRef.current);
    }
  }, [props.readOnly, cardRef.current]);

  useEffect(() => {
    if (
      [
        props.PreAssessmentSequence?.recognition_before,
        props.PreAssessmentSequence?.memory_and_response_before,
        props.PreAssessmentSequence?.hearing_before,
        props.PreAssessmentSequence?.seeing_before,
        props.PreAssessmentSequence?.touch_before,
      ].includes("ไม่ปกติ")
    ) {
      setBeforeDetail(true);
    } else {
      setBeforeDetail(false);
    }
  }, [props.PreAssessmentSequence]);

  const locationOption = useMemo(() => {
    let painArea = props.PreAssessmentSequence?.painArea;
    let checkData =
      (props.masterOptions?.location || []).find((item: any) => item.value === painArea) ===
        undefined && painArea;

    if (checkData) {
      return [
        ...(props.masterOptions?.location || []),
        {
          text: painArea,
          value: painArea,
          key: painArea,
        },
      ];
    } else {
      return props.masterOptions?.location || [];
    }
  }, [props.PreAssessmentSequence?.painArea, props.masterOptions?.location]);

  const characteristicOption = useMemo(() => {
    let painNature = props.PreAssessmentSequence?.painNature;
    let checkData =
      (props.masterOptions?.characteristic || []).find((item: any) => item.value === painNature) ===
        undefined && painNature;

    if (checkData) {
      return [
        ...(props.masterOptions?.characteristic || []),
        {
          text: painNature,
          value: painNature,
          key: painNature,
        },
      ];
    } else {
      return props.masterOptions?.characteristic || [];
    }
  }, [props.PreAssessmentSequence?.painNature, props.masterOptions?.characteristic]);

  const contactOptions = useMemo(() => {
    let contactPerson = props.PreAssessmentSequence?.contact_person_relation;
    let checkData =
      (props.preAssessmentOptions?.contactOptions || []).find(
        (item: any) => item.value === contactPerson
      ) === undefined && contactPerson;

    if (checkData) {
      return [
        ...(props.preAssessmentOptions?.contactOptions || []),
        {
          text: contactPerson,
          value: contactPerson,
          key: contactPerson,
        },
      ];
    } else {
      return props.preAssessmentOptions?.contactOptions || [];
    }
  }, [
    props.PreAssessmentSequence?.contact_person_relation,
    props.preAssessmentOptions?.contactOptions,
  ]);

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`PreAssessmentSequence.${data.name}`, data.checked);
    } else {
      if (data?.name === "painArea" && props.PreAssessmentSequence?.painArea !== 1321) {
        props.setProp(`PreAssessmentSequence`, {
          ...props.PreAssessmentSequence,
          [`${data.name}`]: data.value,
          painAreaRemark: "",
        });
      } else if (
        data?.name === "senses_nursing_care" &&
        !(props.PreAssessmentSequence?.senses_nursing_care || []).includes("อื่นๆ")
      ) {
        props.setProp(`PreAssessmentSequence`, {
          ...props.PreAssessmentSequence,
          [`${data.name}`]: data.value,
          sensesNursingCareRemark: "",
        });
      } else if (presentIntelligence.includes(data.name) && data.value !== "ไม่ปกติ") {
        props.setProp(`PreAssessmentSequence`, {
          ...props.PreAssessmentSequence,
          [`${data.name}`]: data.value,
          [`${data.name}_detail`]: "",
        });
      } else {
        props.setProp(`PreAssessmentSequence.${data.name}`, data.value);
      }
    }
  };

  const handleChangeStomachDefecation = (_event: any, data: any) => {
    if (data.checked === true) {
      props.runSequence({
        sequence: "PreAssessment",
        action: "checkStomachDefecation",
        type: data.name,
      });
    } else {
      props.setProp(`PreAssessmentSequence.${data.name}`, data.checked);
    }
  };

  const handleChangePhone = (_event: any, data: any) => {
    const limit = 10;
    props.setProp(`PreAssessmentSequence.${data.name}`, data.value.slice(0, limit));
  };

  const handleChangeCuredDate = (date: string) => {
    props.setProp("PreAssessmentSequence.has_been_cured_date", date);
  };

  const handleActionForm = (_event: any, data: any) => {
    let requireData = false;

    if (
      props.PreAssessmentSequence?.risk_fall === "มีความเสี่ยง" &&
      props.PreAssessmentSequence?.risk_fall_remark === ""
    ) {
      requireData = true;
    } else {
      for (let items of presentIntelligence) {
        if (
          props.PreAssessmentSequence?.[items] === "ไม่ปกติ" &&
          props.PreAssessmentSequence?.[`${items}_detail`] === ""
        ) {
          requireData = true;
        }
      }
    }

    if (requireData) {
      setOpenTextAlert(true);
    } else {
      if (props.PreAssessmentSequence?.id) {
        setOpenSave(true);
      } else {
        props.runSequence({
          sequence: "PreAssessment",
          action: data.name,
          card: CARD_PRE_ASSESSMENT_ID,
          provisional_diagnosis: subProvisionDiagRef?.current?.getSaveData(),
        });
      }
    }
  };

  const handleActionLog = () => {
    props.runSequence({
      sequence: "PreAssessment",
      action: "getActionLog",
    });

    setOpenLog(true);
  };

  const actionLogData = useMemo(
    () =>
      props.preAssessmentActionLog?.map((item: any) => ({
        ...item,
        // massage: <div></div>,
        // division: <div></div>,
        edited: (
          <div>{`${formatDate(moment(item?.datetime))} [${moment(item?.datetime).format(
            `HH:mm`
          )}]`}</div>
        ),
      })),
    [props.preAssessmentActionLog]
  );

  const handleAdditionLocation = (e: any, { value }: any) => {
    props.setProp("masterOptions.location", [
      { text: value, value: value, key: value },
      ...props.masterOptions?.location,
    ]);
  };

  const handleAdditionCharacteristic = (e: any, { value }: any) => {
    props.setProp("masterOptions.characteristic", [
      { text: value, value: value, key: value },
      ...props.masterOptions?.characteristic,
    ]);
  };

  const handleAdditionContact = (e: any, { value }: any) => {
    props.setProp("preAssessmentOptions.contactOptions", [
      { text: value, value: value, key: value },
      ...props.preAssessmentOptions?.contactOptions,
    ]);
  };

  return (
    <div ref={cardRef} style={{ minWidth: "max-content", whiteSpace: "nowrap" }}>
      {(props.errorMessage?.[CARD_PRE_ASSESSMENT_ID] ||
        props.successMessage?.[CARD_PRE_ASSESSMENT_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_PRE_ASSESSMENT_ID}`, null);
            props.setProp(`successMessage.${CARD_PRE_ASSESSMENT_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_PRE_ASSESSMENT_ID]}
          success={props.successMessage?.[CARD_PRE_ASSESSMENT_ID]}
          languageUX={props.languageUX}
        />
      )}

      <CardPreAssessmentHeaderUX
        // seq
        PreAssessmentSequence={props.PreAssessmentSequence}
        getActionLog={handleActionLog}
        edit_user_name={`บันทึกล่าสุดโดย ${props.PreAssessmentSequence?.edit_user_name}`}
        edited={`${formatDate(moment(props.PreAssessmentSequence?.edited_utc))} ${moment(
          props.PreAssessmentSequence?.edited_utc
        ).format("HH:mm")}`}
        // style
        style={
          props.headerColor
            ? {
                borderTop: `2px solid ${props.headerColor}`,
                borderRadius: "0.28571429rem 0.28571429rem 0 0",
              }
            : {}
        }
        // config
        readOnly={props.readOnly}
        closeIcon={props.closeIcon}
        closeSave={props.closeSave}
        // callback
        onClose={props.onClose}
        // Element
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionForm}
            // data
            paramKey={`${CARD_PRE_ASSESSMENT_ID}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_PRE_ASSESSMENT_ID}_SAVE`]}
            // config
            disabled={props.readOnly}
            color={props.PreAssessmentSequence?.id ? "yellow" : "green"}
            name="SAVE"
            size="small"
            title={
              props.PreAssessmentSequence?.id
                ? intl.formatMessage({ id: "บันทึกการแก้ไข" })
                : intl.formatMessage({ id: "บันทึก" })
            }
            style={{ display: props.readOnly ? "none" : "" }}
          />
        }
        languageUX={props.languageUX}
      />
      <CardLayout
        titleText={"ข้อมูลทั่วไป"}
        titleColor="rgba(40, 188, 208, 0.65)"
        toggleable={true}
        closeable={false}
        centerTitle={true}
        fullWidth={true}
        style={props.headerColor ? { borderTopRightRadius: 0, borderTopLeftRadius: 0 } : {}}
        dividerStyle={{ display: "none" }}
        onClose={props.onClose}
      >
        <div style={{ ...(props.isPHV && { pointerEvents: "none" }) }}>
          <CardPreAssessmentGeneralUX
            // function
            onChangeData={handleChangeData}
            onChangePhone={handleChangePhone}
            onAdditionContact={handleAdditionContact}
            // seq
            PreAssessmentSequence={props.PreAssessmentSequence}
            // options
            preAssessmentOptions={props.preAssessmentOptions}
            contactOptions={contactOptions}
            // subProvisionDiag
            subProvisionDiag={
              <div style={{ pointerEvents: "none" }}>
                <SubProvisionDiag
                  ref={subProvisionDiagRef}
                  subICDController={props.subICDController}
                  titleCardStyle={{ display: "none" }}
                  raised={false}
                  secondary={false}
                  buttonSaveStyle={{ display: "none" }}
                  isReadOnly={true}
                  languageUX={props.languageUX}
                />
              </div>
            }
            languageUX={props.languageUX}
          />
        </div>
      </CardLayout>
      <CardLayout
        titleText={"ข้อมูลแบบแผนสุขภาพ"}
        titleColor="rgba(40, 188, 208, 0.65)"
        toggleable={true}
        closeable={false}
        centerTitle={true}
        fullWidth={true}
        style={props.headerColor ? { borderTopRightRadius: 0, borderTopLeftRadius: 0 } : {}}
        dividerStyle={{ display: "none" }}
        onClose={props.onClose}
      >
        <div style={{ paddingBottom: "2rem", ...(props.isPHV && { pointerEvents: "none" }) }}>
          <CardPreAssessmentHealthUX
            // function
            onChangeData={handleChangeData}
            onChangeStomachDefecation={handleChangeStomachDefecation}
            onAdditionLocation={handleAdditionLocation}
            onAdditionCharacteristic={handleAdditionCharacteristic}
            // seq
            isShowPregnancy={
              props.selectedPatient?.gender === "F" &&
              props.selectedPatient?.age >= 15 &&
              props.selectedPatient?.age <= 50
            }
            PreAssessmentSequence={props.PreAssessmentSequence}
            beforeDetail={![
              props.PreAssessmentSequence?.recognition_before,
              props.PreAssessmentSequence?.memory_and_response_before,
              props.PreAssessmentSequence?.hearing_before,
              props.PreAssessmentSequence?.seeing_before,
              props.PreAssessmentSequence?.touch_before,
            ].includes("ไม่ปกติ")}
            // options
            preAssessmentOptions={props.preAssessmentOptions}
            masterOptions={props.masterOptions}
            characteristicOption={characteristicOption}
            locationOption={locationOption}
            // check input number
            onKeyDown={(e: any) => {
              if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            languageUX={props.languageUX}
          />
        </div>
      </CardLayout>

      <Modal open={openLog}>
        <CardPreAssessmentActionLogUX
          actionLogData={actionLogData}
          onDismiss={() => setOpenLog(false)}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal size="tiny" open={openSave} onClose={() => setOpenSave(false)}>
        <Segment inverted className="yellow" style={{ margin: "0px" }}>
          {intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        </Segment>
        <div style={{ margin: "10px 20px" }}>
          <div style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "กรุณาใส่เหตุผลในการแก้ไข" })}
          </div>

          <div style={{ margin: "10px 0px" }}>
            <Input
              fluid={true}
              value={textReason}
              onChange={(e, { value }) => setTextReason(value)}
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="blue"
              onClick={() => {
                if (textReason !== "") {
                  setOpenSave(false);
                  props.runSequence({
                    sequence: "PreAssessment",
                    action: "SAVE",
                    card: CARD_PRE_ASSESSMENT_ID,
                    note: textReason,
                  });
                  setTextReason("");
                }
              }}
              style={{ margin: "0px 20px" }}
            >
              บันทึก
            </Button>
            <Button color="red" onClick={() => setOpenSave(false)} style={{ margin: "0px 20px" }}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </Modal>

      <ModInfoError
        titleColor="red"
        titleName="แจ้งเตือน"
        btnText="ตกลง"
        size="mini"
        open={openTextAlert}
        alertText={"กรอกข้อมูลไม่ครบถ้วน"}
        onApprove={() => setOpenTextAlert(false)}
      />

      {/* <CardPreAssessmentUX
        // function
        onChangeData={handleChangeData}
        onChangeCuredDate={handleChangeCuredDate}
        onActionForm={handleActionForm}
        // seq
        PreAssessmentSequence={props.PreAssessmentSequence}
        // options
        preAssessmentOptions={props.preAssessmentOptions}
        // component
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionForm}
            // data
            paramKey={`${CARD_PRE_ASSESSMENT_ID}_SAVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_PRE_ASSESSMENT_ID}_SAVE`]
            }
            // config
            color="green"
            name="SAVE"
            size="small"
            style={{
              display:
                props.PreAssessmentSequence?.id &&
                !props.PreAssessmentSequence?.allowed_actions?.includes("SAVE")
                  ? "none"
                  : "",
            }}
            title="SAVE"
          />
        }
        buttonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionForm}
            // data
            paramKey={`${CARD_PRE_ASSESSMENT_ID}_CONFIRM`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_PRE_ASSESSMENT_ID}_CONFIRM`]
            }
            // config
            color="teal"
            name="CONFIRM"
            size="small"
            style={{
              display: props.PreAssessmentSequence?.allowed_actions?.includes(
                "CONFIRM"
              )
                ? ""
                : "none",
            }}
            title="CONFIRM"
          />
        }
        buttonUnconfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionForm}
            // data
            paramKey={`${CARD_PRE_ASSESSMENT_ID}_UNCONFIRM`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_PRE_ASSESSMENT_ID}_UNCONFIRM`]
            }
            // config
            color="orange"
            name="UNCONFIRM"
            size="small"
            style={{
              display: props.PreAssessmentSequence?.allowed_actions?.includes(
                "UNCONFIRM"
              )
                ? ""
                : "none",
            }}
            title="UNCONFIRM"
          />
        }
        buttonCancel={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionForm}
            // data
            paramKey={`${CARD_PRE_ASSESSMENT_ID}_CANCEL`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_PRE_ASSESSMENT_ID}_CANCEL`]
            }
            // config
            color="red"
            name="CANCEL"
            size="small"
            style={{
              display: props.PreAssessmentSequence?.allowed_actions?.includes(
                "CANCEL"
              )
                ? ""
                : "none",
            }}
            title="CANCEL"
          />
        }
      /> */}
    </div>
  );
};

CardPreAssessment.defaultProps = CardPreAssessmentInitial;

export default React.memo(CardPreAssessment);
