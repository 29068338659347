import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Input,
  Dropdown,
  TextArea
} from 'semantic-ui-react'

const CardPreAssessmentGeneralUX = (props: any) => {
    return(
      <div
        id="CardPreAssessmentGeneralUX-div-0">
        <div
          id="CardPreAssessmentGeneralUX-div-709"
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px" }}>
          ประเมินผู้ป่วยแรกรับ
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-710"
          style={{ marginLeft: "15px" }}>

          <div
            id="CardPreAssessmentGeneralUX-div-711"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-719"
              style={{ width: "13%", fontWeight: "bold",marginRight: "10px"}}>

              <div
                id="CardPreAssessmentGeneralUX-div-721">
                รับใหม่จาก :
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-720"
              style={{ width: "87%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.come_from === "OPD"}
                id="CardPreAssessmentGeneralUX-Radio-722"
                label="OPD"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center" ,marginRight: "20px"}}
                value="OPD">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "ER"}
                id="CardPreAssessmentGeneralUX-Radio-723"
                label="ER"
                name="come_from"
                onChange={props.onChangeData}
                style={{width: "6%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="ER">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "Refer-in"}
                id="CardPreAssessmentGeneralUX-Radio-724"
                label="Refer-in"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "9%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="Refer-in">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "นัดมาผ่าตัด"}
                id="CardPreAssessmentGeneralUX-Radio-725"
                label="นัดมาผ่าตัด"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "10%",  display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="นัดมาผ่าตัด">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "อื่นๆ"}
                id="CardPreAssessmentGeneralUX-Radio-727"
                label="อื่นๆ โปรดระบุ"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "10%",  display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="อื่นๆ">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-889"
                style={{ display: props.PreAssessmentSequence?.come_from !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" ,marginRight: "20px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.come_from !== "อื่นๆ"}
                id="CardPreAssessmentGeneralUX-Input-726"
                name="come_from_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.come_from=== "อื่นๆ"  ? props.PreAssessmentSequence?.come_from_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-712"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-728"
              style={{ width: "13%", fontWeight: "bold" ,marginRight: "10px"}}>

              <div
                id="CardPreAssessmentGeneralUX-div-729">
                สถานภาพผู้ป่วยแรกรับ :
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-731"
              style={{ width: "87%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "เดินมา"}
                id="CardPreAssessmentGeneralUX-Radio-732"
                label="เดินมา"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="เดินมา">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "รถนั่ง"}
                id="CardPreAssessmentGeneralUX-Radio-733"
                label="รถนั่ง"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "6%",  display: "flex", alignItems: "center",marginRight: "20px" }}
                value="รถนั่ง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "รถนอน"}
                id="CardPreAssessmentGeneralUX-Radio-734"
                label="รถนอน"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "9%",  display: "flex", alignItems: "center",marginRight: "20px" }}
                value="รถนอน">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "อื่นๆ"}
                id="CardPreAssessmentGeneralUX-Radio-735"
                label="อื่นๆ โปรดระบุ"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="อื่นๆ">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-890"
                style={{ display: props.PreAssessmentSequence?.commute_by !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" ,marginRight: "20px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.commute_by !== "อื่นๆ"}
                id="CardPreAssessmentGeneralUX-Input-736"
                name="commute_by_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.commute_by === "อื่นๆ"  ? props.PreAssessmentSequence?.commute_by_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-737"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-738"
              style={{ width: "13%", fontWeight: "bold" ,marginRight: "10px"}}>

              <div
                id="CardPreAssessmentGeneralUX-div-740">
                นำส่งหอผู้ป่วย :
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-739"
              style={{ width: "87%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "ญาติ"}
                id="CardPreAssessmentGeneralUX-Radio-741"
                label="ญาติ"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="ญาติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "เวรเปล"}
                id="CardPreAssessmentGeneralUX-Radio-742"
                label="เวรเปล"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="เวรเปล">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "ผู้ป่วยมาเอง"}
                id="CardPreAssessmentGeneralUX-Radio-743"
                label="ผู้ป่วยมาเอง"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "9%",  display: "flex", alignItems: "center",marginRight: "20px" }}
                value="ผู้ป่วยมาเอง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "อื่นๆ"}
                id="CardPreAssessmentGeneralUX-Radio-744"
                label="อื่นๆ โปรดระบุ"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center" ,marginRight: "20px" }}
                value="อื่นๆ">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-891"
                style={{ display: props.PreAssessmentSequence?.commute_with !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" ,marginRight: "20px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.commute_with !== "อื่นๆ"}
                id="CardPreAssessmentGeneralUX-Input-745"
                name="commute_with_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.commute_with === "อื่นๆ"  ? props.PreAssessmentSequence?.commute_with_remark || ""   : ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-747"
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          ประวัติส่วนตัว / ครอบครัว
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-746"
          style={{ marginLeft: "15px" }}>

          <div
            id="CardPreAssessmentGeneralUX-div-748"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-755"
              style={{ width: "11%", fontWeight: "bold" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-757">
                สุขภาพ :
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-756"
              style={{ width: "90%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.health=== "แข็งแรง"}
                id="CardPreAssessmentGeneralUX-Radio-758"
                label="แข็งแรง"
                name="health"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="แข็งแรง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health=== "ไม่แข็งแรง"}
                id="CardPreAssessmentGeneralUX-Radio-759"
                label="ไม่แข็งแรง ระบุ"
                name="health"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ไม่แข็งแรง">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-892"
                style={{ display: props.PreAssessmentSequence?.health !==  "ไม่แข็งแรง" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.health !== "ไม่แข็งแรง" }
                id="CardPreAssessmentGeneralUX-Input-760"
                name="health_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.health=== "ไม่แข็งแรง"  ? props.PreAssessmentSequence?.health_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-749"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-761"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-763"
                style={{ width: "33%", display: "flex", alignItems: "center", fontWeight: "bold",marginRight: "10px" }}>
                สถานภาพสมรส :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-835"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-836"
                  name="marital_status"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.maritalStatusOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.marital_status}>
                </Dropdown>
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-762"
              style={{ width: "33%", display: "flex"}}>

              <div
                id="CardPreAssessmentGeneralUX-div-765"
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ระดับการศึกษา :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-766"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-770"
                  name="education"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.educationOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.education}>
                </Dropdown>
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-834"
              style={{ width: "33%", display: "flex"}}>

              <div
                id="CardPreAssessmentGeneralUX-div-837"
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                อาชีพ :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-838"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-839"
                  name="occupation"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.careerOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.occupation}>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-750"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-772"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-774"
                style={{ width: "33%", display: "flex", alignItems: "center", fontWeight: "bold",marginRight: "10px" }}>
                ศาสนา :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-840"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-841"
                  name="religion"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.religionOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.religion}>
                </Dropdown>
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-773"
              style={{ width: "33%", display: "flex"}}>

              <div
                id="CardPreAssessmentGeneralUX-div-776"
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                เชื้อชาติ :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-777"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-781"
                  name="race"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.raceOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.race}>
                </Dropdown>
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-842"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-843"
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                สัญชาติ :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-844"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-845"
                  name="nationality"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.nationalityOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.nationality}>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-751"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-783"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-785"
                style={{ width: "33%", display: "flex", alignItems: "center", fontWeight: "bold",marginRight: "10px"  }}>
                รายได้เฉลี่ยต่อเดือน :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-846"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-847"
                  name="salary"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.salaryOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.salary || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-784"
              style={{ width: "67%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-787"
                style={{ marginRight: "2%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ค่าใช้จ่ายในการรักษาพยาบาล :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-788"
                style={{ display: "flex",width:"100%"}}>

                <Radio
                  checked={props.PreAssessmentSequence?.cost_problem=== "ไม่มีปัญหา"}
                  id="CardPreAssessmentGeneralUX-Radio-789"
                  label="ไม่มีปัญหา"
                  name="cost_problem"
                  onChange={props.onChangeData}
                  style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                  value="ไม่มีปัญหา">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cost_problem=== "มีปัญหา"}
                  id="CardPreAssessmentGeneralUX-Radio-790"
                  label="มีปัญหา โปรดระบุ"
                  name="cost_problem"
                  onChange={props.onChangeData}
                  style={{ margin: "0 2% 0 15px", display: "flex", alignItems: "center"  }}
                  value="มีปัญหา">
                </Radio>
                <div
                  id="CardPreAssessmentGeneralUX-div-893"
                  style={{ display: props.PreAssessmentSequence?.cost_problem !== "มีปัญหา" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                  *
                </div>
                <Input
                  disabled={props.PreAssessmentSequence?.cost_problem !== "มีปัญหา"}
                  id="CardPreAssessmentGeneralUX-Input-848"
                  name="cost_problem_remark"
                  onChange={props.onChangeData}
                  style={{ width: "60%", margin: "0px 20px" }}
                  value={props.PreAssessmentSequence?.cost_problem === "มีปัญหา" ?  props.PreAssessmentSequence?.cost_problem_remark || ""   : ""}>
                </Input>
              </div>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-752"
            style={ { display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-794"
              style={{ width: "11%", fontWeight: "bold",marginRight: "10px" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-797">
                ผู้ให้ข้อมูล :
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-795"
              style={{ width: "90%", display: "flex"}}>

              <Input
                id="CardPreAssessmentGeneralUX-Input-798"
                name="info_given_by"
                onChange={props.onChangeData}
                style={{ width: "21%" }}
                value={props.PreAssessmentSequence?.info_given_by || ""}>
              </Input>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-753"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-799"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-806"
                style={{ width: "33%", display: "flex", alignItems: "center", fontWeight: "bold",marginRight: "10px"  }}>
                ผู้ติดต่อ :
              </div>
              <Input
                id="CardPreAssessmentGeneralUX-Input-850"
                name="contact_person"
                onChange={props.onChangeData}
                style={{ width: "55%", itemAlign: "left" }}
                value={props.PreAssessmentSequence?.contact_person || ""}>
              </Input>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-800"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-802"
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                เกี่ยวข้องเป็น :
              </div>
              <div
                id="CardPreAssessmentGeneralUX-div-887"
                style={{ width: "55%", itemAlign: "left" }}>

                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  fluid={true}
                  id="CardPreAssessmentGeneralUX-Dropdown-888"
                  name="contact_person_relation"
                  onAddItem={props.onAdditionContact}
                  onChange={props.onChangeData}
                  options={props.contactOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.contact_person_relation || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-849"
              style={{ width: "33%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-851"
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                เบอร์โทรศัพท์ :
              </div>
              <Input
                id="CardPreAssessmentGeneralUX-Input-852"
                name="phone"
                onChange={props.onChangePhone}
                style={{ width: "55%", itemAlign: "left" }}
                type="number"
                value={props.PreAssessmentSequence?.phone || ""}>
              </Input>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-754"
            style={{ display: "flex", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-813"
              style={{ width: "11%", fontWeight: "bold",marginRight: "10px" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-815"
                style={{ marginTop: "10px" }}>
                ที่อยู่ที่ติดต่อได้ :
              </div>
            </div>
            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-814"
              style={{ width: "90%", display: "flex"}}>

              <TextArea
                id="CardPreAssessmentGeneralUX-TextArea-816"
                name="address"
                onChange={props.onChangeData}
                style={{ width: "100%" }}
                value={props.PreAssessmentSequence?.address || ""}>
              </TextArea>
            </div>
          </div>
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-818"
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          การเจ็บป่วยในปัจจุบัน
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-817"
          style={{ marginLeft: "15px" }}>

          <div
            id="CardPreAssessmentGeneralUX-div-819"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-821"
              style={{ width: "100%", fontWeight: "bold", display: "flex", }}>

              <div
                id="CardPreAssessmentGeneralUX-div-823"
                style={{ fontWeight: "bold", margin: "10px 15px 0px 0px", width: "10%" }}>
                อาการสำคัญ :
              </div>
              <TextArea
                id="CardPreAssessmentGeneralUX-TextArea-901"
                name="important_symptoms"
                onChange={props.onChangeData}
                style={{ width: "80%" }}
                value={props.PreAssessmentSequence?.important_symptoms || ""}>
              </TextArea>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-898">

            <div
              id="CardPreAssessmentGeneralUX-div-899"
              style={{ fontWeight: "bold", marginRight: "15px" }}>
              การวินิจฉัยโรคเมื่อแรกรับ :
            </div>
            <div
              id="CardPreAssessmentGeneralUX-div-900"
              style={{ margin: "15px 0px" }}>
              {props.subProvisionDiag}
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-820"
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>

            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-822"
              style={{ width: "100%", display: "flex" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-831"
                style={{ fontWeight: "bold", margin: "10px 15px 0px 0px", width: "13%",}}>
                อาการเจ็บป่วยในปัจจุบัน :
              </div>
              <TextArea
                id="CardPreAssessmentGeneralUX-TextArea-902"
                name="current_illnesses"
                onChange={props.onChangeData}
                style={{ width: "80%" }}
                value={props.PreAssessmentSequence?.current_illnesses || ""}>
              </TextArea>
            </div>
          </div>
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-853"
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          ประวัติเจ็บป่วยในอดีต
        </div>
        <div
          id="CardPreAssessmentGeneralUX-div-854"
          style={{ marginLeft: "15px" }}>

          <div
            id="CardPreAssessmentGeneralUX-div-855"
            style={{ display: "flex",  margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-856"
              style={{ width: "17%", fontWeight: "bold",marginRight: "10px" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-858"
                style={{ marginTop: "10px" }}>
                โรคประจำตัว :
              </div>
            </div>
            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-857"
              style={{ width: "83%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.personal_disease === "ไม่มี"}
                id="CardPreAssessmentGeneralUX-Radio-859"
                label="ไม่มี"
                name="personal_disease"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.personal_disease === "ไม่ทราบ"}
                id="CardPreAssessmentGeneralUX-Radio-860"
                label="ไม่ทราบ"
                name="personal_disease"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.personal_disease === "มี"}
                id="CardPreAssessmentGeneralUX-Radio-861"
                label="มี ระบุ"
                name="personal_disease"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="มี">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-894"
                style={{ display: props.PreAssessmentSequence?.personal_disease !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <TextArea
                disabled={props.PreAssessmentSequence?.personal_disease !== "มี"}
                id="CardPreAssessmentGeneralUX-TextArea-903"
                name="personal_disease_remark"
                onChange={props.onChangeData}
                style={{ width: "30%", ...( props.PreAssessmentSequence?.personal_disease !== "มี" && { marginLeft: "17px" } ) }}
                value={props.PreAssessmentSequence?.personal_disease === "มี" ? props.PreAssessmentSequence?.personal_disease_remark || "" : ""}>
              </TextArea>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-863"
            style={{ display: "flex", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-864"
              style={{ width: "17%", fontWeight: "bold",marginRight: "10px" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-866"
                style={{ marginTop: "10px" }}>
                เคยรับการรักษาในโรงพยาบาล :
              </div>
            </div>
            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-865"
              style={{ width: "83%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.has_been_cured_before === "ไม่มี"}
                id="CardPreAssessmentGeneralUX-Radio-875"
                label="ไม่มี"
                name="has_been_cured_before"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.has_been_cured_before === "ไม่ทราบ"}
                id="CardPreAssessmentGeneralUX-Radio-876"
                label="ไม่ทราบ"
                name="has_been_cured_before"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.has_been_cured_before === "มี"}
                id="CardPreAssessmentGeneralUX-Radio-877"
                label="มี ระบุ"
                name="has_been_cured_before"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="มี">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-895"
                style={{ display: props.PreAssessmentSequence?.has_been_cured_before !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <TextArea
                disabled={props.PreAssessmentSequence?.has_been_cured_before !== "มี"}
                id="CardPreAssessmentGeneralUX-TextArea-904"
                name="has_been_cured_before_remark"
                onChange={props.onChangeData}
                style={{ width: "30%", ...( props.PreAssessmentSequence?.has_been_cured_before !== "มี" && { marginLeft: "17px" } ) }}
                value={props.PreAssessmentSequence?.has_been_cured_before === "มี" ? props.PreAssessmentSequence?.has_been_cured_before_remark || "" : ""}>
              </TextArea>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-867"
            style={{ display: "flex", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-869"
              style={{ width: "17%", fontWeight: "bold",marginRight: "10px" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-871"
                style={{ marginTop: "10px" }}>
                ประวัติการผ่าตัด :
              </div>
            </div>
            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-870"
              style={{ width: "83%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.surgical_history === "ไม่มี"}
                id="CardPreAssessmentGeneralUX-Radio-878"
                label="ไม่มี"
                name="surgical_history"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.surgical_history === "ไม่ทราบ"}
                id="CardPreAssessmentGeneralUX-Radio-879"
                label="ไม่ทราบ"
                name="surgical_history"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.surgical_history === "มี"}
                id="CardPreAssessmentGeneralUX-Radio-880"
                label="มี ระบุ"
                name="surgical_history"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="มี">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-896"
                style={{ display: props.PreAssessmentSequence?.surgical_history !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <TextArea
                disabled={props.PreAssessmentSequence?.surgical_history !== "มี" }
                id="CardPreAssessmentGeneralUX-TextArea-905"
                name="surgical_history_remark"
                onChange={props.onChangeData}
                style={{ width: "30%", ...( props.PreAssessmentSequence?.surgical_history !== "มี" && { marginLeft: "17px" } ) }}
                value={props.PreAssessmentSequence?.surgical_history === "มี" ? props.PreAssessmentSequence?.surgical_history_remark || "" : ""}>
              </TextArea>
            </div>
          </div>
          <div
            id="CardPreAssessmentGeneralUX-div-868"
            style={{ display: "flex", margin: "10px" }}>

            <div
              id="CardPreAssessmentGeneralUX-div-872"
              style={{ width: "17%", fontWeight: "bold",marginRight: "10px" }}>

              <div
                id="CardPreAssessmentGeneralUX-div-874"
                style={{ marginTop: "10px" }}>
                ประวัติการเจ็บป่วยในครอบครัว :
              </div>
            </div>
            <div
              className="ui form"
              id="CardPreAssessmentGeneralUX-div-873"
              style={{ width: "83%", display: "flex"}}>

              <Radio
                checked={props.PreAssessmentSequence?.family_disease === "ไม่มี"}
                id="CardPreAssessmentGeneralUX-Radio-881"
                label="ไม่มี"
                name="family_disease"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.family_disease === "ไม่ทราบ"}
                id="CardPreAssessmentGeneralUX-Radio-882"
                label="ไม่ทราบ"
                name="family_disease"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.family_disease === "มี"}
                id="CardPreAssessmentGeneralUX-Radio-883"
                label="มี ระบุ"
                name="family_disease"
                onChange={props.onChangeData}
                style={{ margin: "10px 2% 0px 0px", display: "flex",  }}
                value="มี">
              </Radio>
              <div
                id="CardPreAssessmentGeneralUX-div-897"
                style={{ display: props.PreAssessmentSequence?.family_disease !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <TextArea
                disabled={props.PreAssessmentSequence?.family_disease !== "มี"}
                id="CardPreAssessmentGeneralUX-TextArea-906"
                name="family_disease_remark"
                onChange={props.onChangeData}
                style={{ width: "30%", ...( props.PreAssessmentSequence?.family_disease !== "มี" && { marginLeft: "17px" } ) }}
                value={props.PreAssessmentSequence?.family_disease === "มี"   ? props.PreAssessmentSequence?.family_disease_remark || ""   : ""}>
              </TextArea>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPreAssessmentGeneralUX

export const screenPropsDefault = {}

/* Date Time : Mon Feb 17 2025 11:22:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-0"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 709,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินผู้ป่วยแรกรับ"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-709"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 710,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-710"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 711,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-711"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 711,
      "void": false
    },
    {
      "from": null,
      "id": 712,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-712"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 712,
      "void": false
    },
    {
      "from": null,
      "id": 719,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-719"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", fontWeight: \"bold\",marginRight: \"10px\"}"
        }
      },
      "seq": 719,
      "void": false
    },
    {
      "from": null,
      "id": 720,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-720"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"87%\", display: \"flex\"}"
        }
      },
      "seq": 720,
      "void": false
    },
    {
      "from": null,
      "id": 721,
      "name": "div",
      "parent": 719,
      "props": {
        "children": {
          "type": "value",
          "value": "รับใหม่จาก :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-721"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 721,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 722,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from === \"OPD\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-722"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\"}"
        },
        "value": {
          "type": "value",
          "value": "OPD"
        }
      },
      "seq": 722,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 723,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"ER\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-723"
        },
        "label": {
          "type": "value",
          "value": "ER"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ER"
        }
      },
      "seq": 723,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 724,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"Refer-in\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-724"
        },
        "label": {
          "type": "value",
          "value": "Refer-in"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "Refer-in"
        }
      },
      "seq": 724,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 725,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"นัดมาผ่าตัด\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-725"
        },
        "label": {
          "type": "value",
          "value": "นัดมาผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\",  display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "นัดมาผ่าตัด"
        }
      },
      "seq": 725,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 726,
      "name": "Input",
      "parent": 720,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from !== \"อื่นๆ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-726"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "come_from_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "placeholder": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"อื่นๆ\"  ? props.PreAssessmentSequence?.come_from_remark || \"\"   : \"\""
        }
      },
      "seq": 889,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 727,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"อื่นๆ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-727"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\",  display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 726,
      "void": false
    },
    {
      "from": null,
      "id": 728,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-728"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", fontWeight: \"bold\" ,marginRight: \"10px\"}"
        }
      },
      "seq": 728,
      "void": false
    },
    {
      "from": null,
      "id": 729,
      "name": "div",
      "parent": 728,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานภาพผู้ป่วยแรกรับ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-729"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 729,
      "void": false
    },
    {
      "from": null,
      "id": 731,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-731"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"87%\", display: \"flex\"}"
        }
      },
      "seq": 731,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 732,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"เดินมา\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-732"
        },
        "label": {
          "type": "value",
          "value": "เดินมา"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "เดินมา"
        }
      },
      "seq": 732,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 733,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"รถนั่ง\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-733"
        },
        "label": {
          "type": "value",
          "value": "รถนั่ง"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\",  display: \"flex\", alignItems: \"center\",marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "รถนั่ง"
        }
      },
      "seq": 733,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 734,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"รถนอน\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-734"
        },
        "label": {
          "type": "value",
          "value": "รถนอน"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\",  display: \"flex\", alignItems: \"center\",marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "รถนอน"
        }
      },
      "seq": 734,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 735,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"อื่นๆ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-735"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 735,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 736,
      "name": "Input",
      "parent": 731,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by !== \"อื่นๆ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-736"
        },
        "name": {
          "type": "value",
          "value": "commute_by_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"อื่นๆ\"  ? props.PreAssessmentSequence?.commute_by_remark || \"\"   : \"\""
        }
      },
      "seq": 890,
      "void": false
    },
    {
      "from": null,
      "id": 737,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-737"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 737,
      "void": false
    },
    {
      "from": null,
      "id": 738,
      "name": "div",
      "parent": 737,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-738"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", fontWeight: \"bold\" ,marginRight: \"10px\"}"
        }
      },
      "seq": 738,
      "void": false
    },
    {
      "from": null,
      "id": 739,
      "name": "div",
      "parent": 737,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-739"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"87%\", display: \"flex\"}"
        }
      },
      "seq": 739,
      "void": false
    },
    {
      "from": null,
      "id": 740,
      "name": "div",
      "parent": 738,
      "props": {
        "children": {
          "type": "value",
          "value": "นำส่งหอผู้ป่วย :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-740"
        }
      },
      "seq": 740,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 741,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"ญาติ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-741"
        },
        "label": {
          "type": "value",
          "value": "ญาติ"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ญาติ"
        }
      },
      "seq": 741,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 742,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"เวรเปล\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-742"
        },
        "label": {
          "type": "value",
          "value": "เวรเปล"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "เวรเปล"
        }
      },
      "seq": 742,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 743,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"ผู้ป่วยมาเอง\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-743"
        },
        "label": {
          "type": "value",
          "value": "ผู้ป่วยมาเอง"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\",  display: \"flex\", alignItems: \"center\",marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ผู้ป่วยมาเอง"
        }
      },
      "seq": 743,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 744,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"อื่นๆ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-744"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\" ,marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 744,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 745,
      "name": "Input",
      "parent": 739,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with !== \"อื่นๆ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-745"
        },
        "name": {
          "type": "value",
          "value": "commute_with_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"อื่นๆ\"  ? props.PreAssessmentSequence?.commute_with_remark || \"\"   : \"\""
        }
      },
      "seq": 891,
      "void": false
    },
    {
      "from": null,
      "id": 746,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-746"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 747,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติส่วนตัว / ครอบครัว"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-747"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 748,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-748"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 748,
      "void": false
    },
    {
      "from": null,
      "id": 749,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-749"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 749,
      "void": false
    },
    {
      "from": null,
      "id": 750,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-750"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 750,
      "void": false
    },
    {
      "from": null,
      "id": 751,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-751"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 751,
      "void": false
    },
    {
      "from": null,
      "id": 752,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-752"
        },
        "style": {
          "type": "code",
          "value": " { display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 752,
      "void": false
    },
    {
      "from": null,
      "id": 753,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-753"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 753,
      "void": false
    },
    {
      "from": null,
      "id": 754,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-754"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"10px\" }"
        }
      },
      "seq": 754,
      "void": false
    },
    {
      "from": null,
      "id": 755,
      "name": "div",
      "parent": 748,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-755"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 755,
      "void": false
    },
    {
      "from": null,
      "id": 756,
      "name": "div",
      "parent": 748,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-756"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 756,
      "void": false
    },
    {
      "from": null,
      "id": 757,
      "name": "div",
      "parent": 755,
      "props": {
        "children": {
          "type": "value",
          "value": "สุขภาพ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-757"
        }
      },
      "seq": 757,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 758,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health=== \"แข็งแรง\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-758"
        },
        "label": {
          "type": "value",
          "value": "แข็งแรง"
        },
        "name": {
          "type": "value",
          "value": "health"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "แข็งแรง"
        }
      },
      "seq": 758,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 759,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health=== \"ไม่แข็งแรง\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-759"
        },
        "label": {
          "type": "value",
          "value": "ไม่แข็งแรง ระบุ"
        },
        "name": {
          "type": "value",
          "value": "health"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่แข็งแรง"
        }
      },
      "seq": 759,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 760,
      "name": "Input",
      "parent": 756,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health !== \"ไม่แข็งแรง\" "
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-760"
        },
        "name": {
          "type": "value",
          "value": "health_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health=== \"ไม่แข็งแรง\"  ? props.PreAssessmentSequence?.health_remark || \"\"   : \"\""
        }
      },
      "seq": 892,
      "void": false
    },
    {
      "from": null,
      "id": 761,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-761"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 761,
      "void": false
    },
    {
      "from": null,
      "id": 762,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-762"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\"}"
        }
      },
      "seq": 762,
      "void": false
    },
    {
      "from": null,
      "id": 763,
      "name": "div",
      "parent": 761,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานภาพสมรส :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-763"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 763,
      "void": false
    },
    {
      "from": null,
      "id": 765,
      "name": "div",
      "parent": 762,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับการศึกษา :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-765"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 765,
      "void": false
    },
    {
      "from": null,
      "id": 766,
      "name": "div",
      "parent": 762,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-766"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 766,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 770,
      "name": "Dropdown",
      "parent": 766,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-770"
        },
        "name": {
          "type": "value",
          "value": "education"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.educationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.education"
        }
      },
      "seq": 770,
      "void": false
    },
    {
      "from": null,
      "id": 772,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-772"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 772,
      "void": false
    },
    {
      "from": null,
      "id": 773,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-773"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\"}"
        }
      },
      "seq": 773,
      "void": false
    },
    {
      "from": null,
      "id": 774,
      "name": "div",
      "parent": 772,
      "props": {
        "children": {
          "type": "value",
          "value": "ศาสนา :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-774"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 774,
      "void": false
    },
    {
      "from": null,
      "id": 776,
      "name": "div",
      "parent": 773,
      "props": {
        "children": {
          "type": "value",
          "value": "เชื้อชาติ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-776"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 776,
      "void": false
    },
    {
      "from": null,
      "id": 777,
      "name": "div",
      "parent": 773,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-777"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 777,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 781,
      "name": "Dropdown",
      "parent": 777,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-781"
        },
        "name": {
          "type": "value",
          "value": "race"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.raceOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.race"
        }
      },
      "seq": 781,
      "void": false
    },
    {
      "from": null,
      "id": 783,
      "name": "div",
      "parent": 751,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-783"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 783,
      "void": false
    },
    {
      "from": null,
      "id": 784,
      "name": "div",
      "parent": 751,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-784"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"67%\", display: \"flex\" }"
        }
      },
      "seq": 784,
      "void": false
    },
    {
      "from": null,
      "id": 785,
      "name": "div",
      "parent": 783,
      "props": {
        "children": {
          "type": "value",
          "value": "รายได้เฉลี่ยต่อเดือน :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-785"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\",marginRight: \"10px\"  }"
        }
      },
      "seq": 785,
      "void": false
    },
    {
      "from": null,
      "id": 787,
      "name": "div",
      "parent": 784,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าใช้จ่ายในการรักษาพยาบาล :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-787"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 787,
      "void": false
    },
    {
      "from": null,
      "id": 788,
      "name": "div",
      "parent": 784,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-788"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",width:\"100%\"}"
        }
      },
      "seq": 788,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 789,
      "name": "Radio",
      "parent": 788,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem=== \"ไม่มีปัญหา\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-789"
        },
        "label": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "cost_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        }
      },
      "seq": 789,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 790,
      "name": "Radio",
      "parent": 788,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem=== \"มีปัญหา\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-790"
        },
        "label": {
          "type": "value",
          "value": "มีปัญหา โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "cost_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0 2% 0 15px\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีปัญหา"
        }
      },
      "seq": 790,
      "void": false
    },
    {
      "from": null,
      "id": 794,
      "name": "div",
      "parent": 752,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-794"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 794,
      "void": false
    },
    {
      "from": null,
      "id": 795,
      "name": "div",
      "parent": 752,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-795"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 795,
      "void": false
    },
    {
      "from": null,
      "id": 797,
      "name": "div",
      "parent": 794,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้ข้อมูล :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-797"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 797,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 798,
      "name": "Input",
      "parent": 795,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-798"
        },
        "name": {
          "type": "value",
          "value": "info_given_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"21%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.info_given_by || \"\""
        }
      },
      "seq": 798,
      "void": false
    },
    {
      "from": null,
      "id": 799,
      "name": "div",
      "parent": 753,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-799"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 799,
      "void": false
    },
    {
      "from": null,
      "id": 800,
      "name": "div",
      "parent": 753,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-800"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 800,
      "void": false
    },
    {
      "from": null,
      "id": 802,
      "name": "div",
      "parent": 800,
      "props": {
        "children": {
          "type": "value",
          "value": "เกี่ยวข้องเป็น :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-802"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 803,
      "void": false
    },
    {
      "from": null,
      "id": 806,
      "name": "div",
      "parent": 799,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ติดต่อ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-806"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\",marginRight: \"10px\"  }"
        }
      },
      "seq": 806,
      "void": false
    },
    {
      "from": null,
      "id": 813,
      "name": "div",
      "parent": 754,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-813"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 813,
      "void": false
    },
    {
      "from": null,
      "id": 814,
      "name": "div",
      "parent": 754,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-814"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 814,
      "void": false
    },
    {
      "from": null,
      "id": 815,
      "name": "div",
      "parent": 813,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่อยู่ที่ติดต่อได้ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-815"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\" }"
        }
      },
      "seq": 815,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 816,
      "name": "TextArea",
      "parent": 814,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-816"
        },
        "name": {
          "type": "value",
          "value": "address"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.address || \"\""
        }
      },
      "seq": 816,
      "void": true
    },
    {
      "from": null,
      "id": 817,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-817"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 818,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "การเจ็บป่วยในปัจจุบัน"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-818"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 819,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-819"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 819,
      "void": false
    },
    {
      "from": null,
      "id": 820,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-820"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 898,
      "void": false
    },
    {
      "from": null,
      "id": 821,
      "name": "div",
      "parent": 819,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-821"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", fontWeight: \"bold\", display: \"flex\", }"
        }
      },
      "seq": 821,
      "void": false
    },
    {
      "from": null,
      "id": 822,
      "name": "div",
      "parent": 820,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-822"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\" }"
        }
      },
      "seq": 822,
      "void": false
    },
    {
      "from": null,
      "id": 823,
      "name": "div",
      "parent": 821,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการสำคัญ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-823"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"10px 15px 0px 0px\", width: \"10%\" }"
        }
      },
      "seq": 823,
      "void": false
    },
    {
      "from": null,
      "id": 831,
      "name": "div",
      "parent": 822,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการเจ็บป่วยในปัจจุบัน :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-831"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"10px 15px 0px 0px\", width: \"13%\",}"
        }
      },
      "seq": 831,
      "void": false
    },
    {
      "from": null,
      "id": 834,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-834"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\"}"
        }
      },
      "seq": 834,
      "void": false
    },
    {
      "from": null,
      "id": 835,
      "name": "div",
      "parent": 761,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-835"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 835,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 836,
      "name": "Dropdown",
      "parent": 835,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-836"
        },
        "name": {
          "type": "value",
          "value": "marital_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.maritalStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.marital_status"
        }
      },
      "seq": 836,
      "void": false
    },
    {
      "from": null,
      "id": 837,
      "name": "div",
      "parent": 834,
      "props": {
        "children": {
          "type": "value",
          "value": "อาชีพ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-837"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 837,
      "void": false
    },
    {
      "from": null,
      "id": 838,
      "name": "div",
      "parent": 834,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-838"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 838,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 839,
      "name": "Dropdown",
      "parent": 838,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-839"
        },
        "name": {
          "type": "value",
          "value": "occupation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.careerOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.occupation"
        }
      },
      "seq": 839,
      "void": false
    },
    {
      "from": null,
      "id": 840,
      "name": "div",
      "parent": 772,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-840"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 840,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 841,
      "name": "Dropdown",
      "parent": 840,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-841"
        },
        "name": {
          "type": "value",
          "value": "religion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.religionOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.religion"
        }
      },
      "seq": 841,
      "void": false
    },
    {
      "from": null,
      "id": 842,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-842"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 842,
      "void": false
    },
    {
      "from": null,
      "id": 843,
      "name": "div",
      "parent": 842,
      "props": {
        "children": {
          "type": "value",
          "value": "สัญชาติ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-843"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 843,
      "void": false
    },
    {
      "from": null,
      "id": 844,
      "name": "div",
      "parent": 842,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-844"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 844,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 845,
      "name": "Dropdown",
      "parent": 844,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-845"
        },
        "name": {
          "type": "value",
          "value": "nationality"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.nationalityOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.nationality"
        }
      },
      "seq": 845,
      "void": false
    },
    {
      "from": null,
      "id": 846,
      "name": "div",
      "parent": 783,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-846"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 846,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 847,
      "name": "Dropdown",
      "parent": 846,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-847"
        },
        "name": {
          "type": "value",
          "value": "salary"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.salaryOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.salary || \"\""
        }
      },
      "seq": 847,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 848,
      "name": "Input",
      "parent": 788,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem !== \"มีปัญหา\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-848"
        },
        "name": {
          "type": "value",
          "value": "cost_problem_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\", margin: \"0px 20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem === \"มีปัญหา\" ?  props.PreAssessmentSequence?.cost_problem_remark || \"\"   : \"\""
        }
      },
      "seq": 893,
      "void": false
    },
    {
      "from": null,
      "id": 849,
      "name": "div",
      "parent": 753,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-849"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 849,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 850,
      "name": "Input",
      "parent": 799,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-850"
        },
        "name": {
          "type": "value",
          "value": "contact_person"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.contact_person || \"\""
        }
      },
      "seq": 850,
      "void": false
    },
    {
      "from": null,
      "id": 851,
      "name": "div",
      "parent": 849,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทรศัพท์ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-851"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 851,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 852,
      "name": "Input",
      "parent": 849,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Input-852"
        },
        "name": {
          "type": "value",
          "value": "phone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePhone"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.phone || \"\""
        }
      },
      "seq": 852,
      "void": false
    },
    {
      "from": null,
      "id": 853,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติเจ็บป่วยในอดีต"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-853"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 854,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-854"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 855,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-855"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  margin: \"10px\" }"
        }
      },
      "seq": 855,
      "void": false
    },
    {
      "from": null,
      "id": 856,
      "name": "div",
      "parent": 855,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-856"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 856,
      "void": false
    },
    {
      "from": null,
      "id": 857,
      "name": "div",
      "parent": 855,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-857"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"83%\", display: \"flex\"}"
        }
      },
      "seq": 857,
      "void": false
    },
    {
      "from": null,
      "id": 858,
      "name": "div",
      "parent": 856,
      "props": {
        "children": {
          "type": "value",
          "value": "โรคประจำตัว :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-858"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\" }"
        }
      },
      "seq": 858,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 859,
      "name": "Radio",
      "parent": 857,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"ไม่มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-859"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "personal_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 859,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 860,
      "name": "Radio",
      "parent": 857,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"ไม่ทราบ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-860"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "personal_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 860,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 861,
      "name": "Radio",
      "parent": 857,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-861"
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "personal_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 861,
      "void": false
    },
    {
      "from": null,
      "id": 863,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-863"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"10px\" }"
        }
      },
      "seq": 863,
      "void": false
    },
    {
      "from": null,
      "id": 864,
      "name": "div",
      "parent": 863,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-864"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 864,
      "void": false
    },
    {
      "from": null,
      "id": 865,
      "name": "div",
      "parent": 863,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-865"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"83%\", display: \"flex\"}"
        }
      },
      "seq": 865,
      "void": false
    },
    {
      "from": null,
      "id": 866,
      "name": "div",
      "parent": 864,
      "props": {
        "children": {
          "type": "value",
          "value": "เคยรับการรักษาในโรงพยาบาล :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-866"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\" }"
        }
      },
      "seq": 866,
      "void": false
    },
    {
      "from": null,
      "id": 867,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-867"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"10px\" }"
        }
      },
      "seq": 867,
      "void": false
    },
    {
      "from": null,
      "id": 868,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-868"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"10px\" }"
        }
      },
      "seq": 868,
      "void": false
    },
    {
      "from": null,
      "id": 869,
      "name": "div",
      "parent": 867,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-869"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 869,
      "void": false
    },
    {
      "from": null,
      "id": 870,
      "name": "div",
      "parent": 867,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-870"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"83%\", display: \"flex\"}"
        }
      },
      "seq": 870,
      "void": false
    },
    {
      "from": null,
      "id": 871,
      "name": "div",
      "parent": 869,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการผ่าตัด :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-871"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\" }"
        }
      },
      "seq": 871,
      "void": false
    },
    {
      "from": null,
      "id": 872,
      "name": "div",
      "parent": 868,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-872"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 872,
      "void": false
    },
    {
      "from": null,
      "id": 873,
      "name": "div",
      "parent": 868,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-873"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"83%\", display: \"flex\"}"
        }
      },
      "seq": 873,
      "void": false
    },
    {
      "from": null,
      "id": 874,
      "name": "div",
      "parent": 872,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการเจ็บป่วยในครอบครัว :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-874"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\" }"
        }
      },
      "seq": 874,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 875,
      "name": "Radio",
      "parent": 865,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"ไม่มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-875"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 875,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 876,
      "name": "Radio",
      "parent": 865,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"ไม่ทราบ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-876"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 876,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 877,
      "name": "Radio",
      "parent": 865,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-877"
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 877,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 878,
      "name": "Radio",
      "parent": 870,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"ไม่มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-878"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "surgical_history"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 878,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 879,
      "name": "Radio",
      "parent": 870,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"ไม่ทราบ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-879"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "surgical_history"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 879,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 880,
      "name": "Radio",
      "parent": 870,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-880"
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "surgical_history"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 880,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 881,
      "name": "Radio",
      "parent": 873,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"ไม่มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-881"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "family_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 881,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 882,
      "name": "Radio",
      "parent": 873,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"ไม่ทราบ\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-882"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "family_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 882,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 883,
      "name": "Radio",
      "parent": 873,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Radio-883"
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "family_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 2% 0px 0px\", display: \"flex\",  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 883,
      "void": false
    },
    {
      "from": null,
      "id": 887,
      "name": "div",
      "parent": 800,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-887"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 887,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 888,
      "name": "Dropdown",
      "parent": 887,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-Dropdown-888"
        },
        "name": {
          "type": "value",
          "value": "contact_person_relation"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionContact"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.contactOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.contact_person_relation || \"\""
        }
      },
      "seq": 888,
      "void": false
    },
    {
      "from": null,
      "id": 889,
      "name": "div",
      "parent": 720,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-889"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.come_from !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 727,
      "void": false
    },
    {
      "from": null,
      "id": 890,
      "name": "div",
      "parent": 731,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-890"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.commute_by !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 736,
      "void": false
    },
    {
      "from": null,
      "id": 891,
      "name": "div",
      "parent": 739,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-891"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.commute_with !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 745,
      "void": false
    },
    {
      "from": null,
      "id": 892,
      "name": "div",
      "parent": 756,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-892"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.health !==  \"ไม่แข็งแรง\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 760,
      "void": false
    },
    {
      "from": null,
      "id": 893,
      "name": "div",
      "parent": 788,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-893"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.cost_problem !== \"มีปัญหา\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 848,
      "void": false
    },
    {
      "from": null,
      "id": 894,
      "name": "div",
      "parent": 857,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-894"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.personal_disease !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 862,
      "void": false
    },
    {
      "from": null,
      "id": 895,
      "name": "div",
      "parent": 865,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-895"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.has_been_cured_before !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 884,
      "void": false
    },
    {
      "from": null,
      "id": 896,
      "name": "div",
      "parent": 870,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-896"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.surgical_history !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 885,
      "void": false
    },
    {
      "from": null,
      "id": 897,
      "name": "div",
      "parent": 873,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-897"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.family_disease !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 886,
      "void": false
    },
    {
      "from": null,
      "id": 898,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-898"
        }
      },
      "seq": 820,
      "void": false
    },
    {
      "from": null,
      "id": 899,
      "name": "div",
      "parent": 898,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัยโรคเมื่อแรกรับ :"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-899"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"15px\" }"
        }
      },
      "seq": 899,
      "void": false
    },
    {
      "from": null,
      "id": 900,
      "name": "div",
      "parent": 898,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subProvisionDiag"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-div-900"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px 0px\" }"
        }
      },
      "seq": 900,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 901,
      "name": "TextArea",
      "parent": 821,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-901"
        },
        "name": {
          "type": "value",
          "value": "important_symptoms"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.important_symptoms || \"\""
        }
      },
      "seq": 901,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 902,
      "name": "TextArea",
      "parent": 822,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-902"
        },
        "name": {
          "type": "value",
          "value": "current_illnesses"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.current_illnesses || \"\""
        }
      },
      "seq": 902,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 903,
      "name": "TextArea",
      "parent": 857,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease !== \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-903"
        },
        "name": {
          "type": "value",
          "value": "personal_disease_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", ...( props.PreAssessmentSequence?.personal_disease !== \"มี\" && { marginLeft: \"17px\" } ) }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"มี\" ? props.PreAssessmentSequence?.personal_disease_remark || \"\" : \"\""
        }
      },
      "seq": 903,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 904,
      "name": "TextArea",
      "parent": 865,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before !== \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-904"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", ...( props.PreAssessmentSequence?.has_been_cured_before !== \"มี\" && { marginLeft: \"17px\" } ) }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"มี\" ? props.PreAssessmentSequence?.has_been_cured_before_remark || \"\" : \"\""
        }
      },
      "seq": 904,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 905,
      "name": "TextArea",
      "parent": 870,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history !== \"มี\" "
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-905"
        },
        "name": {
          "type": "value",
          "value": "surgical_history_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", ...( props.PreAssessmentSequence?.surgical_history !== \"มี\" && { marginLeft: \"17px\" } ) }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"มี\" ? props.PreAssessmentSequence?.surgical_history_remark || \"\" : \"\""
        }
      },
      "seq": 905,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 906,
      "name": "TextArea",
      "parent": 873,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease !== \"มี\""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentGeneralUX-TextArea-906"
        },
        "name": {
          "type": "value",
          "value": "family_disease_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", ...( props.PreAssessmentSequence?.family_disease !== \"มี\" && { marginLeft: \"17px\" } ) }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"มี\"   ? props.PreAssessmentSequence?.family_disease_remark || \"\"   : \"\""
        }
      },
      "seq": 906,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardPreAssessmentGeneralUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
