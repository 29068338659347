import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Icon
} from 'semantic-ui-react'

const CardPreAssessmentHeaderUX = (props: any) => {
    return(
      <div
        id="CardPreAssessmentHeaderUX-div-0"
        style={{"padding":"6px 10px 6px 20px","borderBottomLeftRadius":"0","borderBottomRightRadius":"0","border":"1px solid rgba(34,36,38,.15)",...(props.style || {})}}>
        <div
          id="CardPreAssessmentHeaderUX-div-709"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center",   minHeight: "40px"}}>
          
          <div
            id="CardPreAssessmentHeaderUX-div-710"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Nursing Admission Assessment
          </div>
          <div
            id="CardPreAssessmentHeaderUX-div-711"
            style={{ display: "flex" , alignItems: "center"}}>
            
            <div
              id="CardPreAssessmentHeaderUX-div-712"
              style={{ textAlign: "right", ...(props.closeSave && { display: "none" }) }}>
              
              <div
                id="CardPreAssessmentHeaderUX-div-715"
                style={{ display: props.PreAssessmentSequence?.id ? "block" :  "none"}}>
                {props.edit_user_name}
              </div>
              <div
                id="CardPreAssessmentHeaderUX-div-716"
                style={{ display: props.PreAssessmentSequence?.id ? "block" :  "none"}}>
                {props.edited}
              </div>
            </div>
            <div
              id="CardPreAssessmentHeaderUX-div-719"
              style={{ margin: "0px 10px", ...(props.closeSave && { display: "none" }) }}>
              {props.buttonSave}
            </div>
            <Button
              disabled={props.readOnly}
              id="CardPreAssessmentHeaderUX-Button-718"
              onClick={props.getActionLog}
              size="small"
              style={{display: props.readOnly ? "none" : "", height: "fit-content"}}>
              Log
            </Button>
            <div
              id="CardPreAssessmentHeaderUX-div-721"
              style={{marginLeft: "1rem", display: props.closeIcon ? "": "none"}}>
              
              <Icon
                className="red large ignore-prevent"
                id="CardPreAssessmentHeaderUX-Icon-722"
                link={true}
                name="close"
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPreAssessmentHeaderUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 20 2025 14:41:26 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{\"padding\":\"6px 10px 6px 20px\",\"borderBottomLeftRadius\":\"0\",\"borderBottomRightRadius\":\"0\",\"border\":\"1px solid rgba(34,36,38,.15)\",...(props.style || {})}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 709,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-709"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\",   minHeight: \"40px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 710,
      "name": "div",
      "parent": 709,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Admission Assessment"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-710"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 710,
      "void": false
    },
    {
      "from": null,
      "id": 711,
      "name": "div",
      "parent": 709,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-711"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" , alignItems: \"center\"}"
        }
      },
      "seq": 711,
      "void": false
    },
    {
      "from": null,
      "id": 712,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-712"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", ...(props.closeSave && { display: \"none\" }) }"
        }
      },
      "seq": 712,
      "void": false
    },
    {
      "from": null,
      "id": 715,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "code",
          "value": "props.edit_user_name"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-715"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.id ? \"block\" :  \"none\"}"
        }
      },
      "seq": 715,
      "void": false
    },
    {
      "from": null,
      "id": 716,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "code",
          "value": "props.edited"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-716"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.id ? \"block\" :  \"none\"}"
        }
      },
      "seq": 716,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 718,
      "name": "Button",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": "Log"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-Button-718"
        },
        "onClick": {
          "type": "code",
          "value": "props.getActionLog"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.readOnly ? \"none\" : \"\", height: \"fit-content\"}"
        }
      },
      "seq": 720,
      "void": false
    },
    {
      "from": null,
      "id": 719,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-719"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", ...(props.closeSave && { display: \"none\" }) }"
        }
      },
      "seq": 717,
      "void": false
    },
    {
      "from": null,
      "id": 721,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-div-721"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\", display: props.closeIcon ? \"\": \"none\"}"
        }
      },
      "seq": 721,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 722,
      "name": "Icon",
      "parent": 721,
      "props": {
        "className": {
          "type": "value",
          "value": "red large ignore-prevent"
        },
        "id": {
          "type": "value",
          "value": "CardPreAssessmentHeaderUX-Icon-722"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 722,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardPreAssessmentHeaderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
